import React from "react";
import logo from './asset/images/en/logo.png'
import img_main from './asset/images/jp/img_main.png'
import img_7777usdt from './asset/images/jp/img_7777usdt.png'
import img_issue1 from './asset/images/jp/img_issue1.png'
import img_issue2 from './asset/images/jp/img_issue2.png'
import img_issue3 from './asset/images/jp/img_issue3.png'
import img_issue4 from './asset/images/jp/img_issue4.png'
import img_issue5 from './asset/images/jp/img_issue5.png'
import img_issue6 from './asset/images/jp/img_issue6.png'
import img_issue7 from './asset/images/jp/img_issue7.png'
import img_issue8 from './asset/images/jp/img_issue8.png'
import img_form from './asset/images/jp/img_form.png'
import img_card1 from './asset/images/jp/img_card1.png'
import img_card2 from './asset/images/jp/img_card2.png'
import img_card3 from './asset/images/jp/img_card3.png'
import img_card4 from './asset/images/jp/img_card4.png'
import img_book from './asset/images/jp/img_book.png'
import img_colour from './asset/images/jp/img_colour.png'
import jp_logo from './asset/images/en/jp_logo.png'
import MenuModal from './MenuModal'
import { Link } from 'react-router-dom';
import img_33card from './asset/images/en/img_33card.png'



const Home = ({
  isEnglish,
  toggle
}) => {
  const renderHeader = () => {
    return (
      <header className="header">
      <a href="https://ovobsc.ovo.space" target="_blank">
          <img src={logo} width="62px" height="22px" alt="logo" />
        </a>
        <div className="header-right">
          <Link to="/en" onClick={toggle}>
            <button className={`button ${isEnglish ? 'isSelected' : ''}`}>English</button>
          </Link>
          <Link to="/">
            <button className={`button ${isEnglish ? '' : 'isSelected'}`}>日本語</button>
          </Link>
          {/* <MenuModal>
            <img src={jp_logo} alt="jp_logo"  width="22px" height="22px" />
          </MenuModal> */}
        </div>
      </header>
    )
  }
  return (
    <div className="home">
      {renderHeader()}
      <section className="first-section">
        <div>
          <img className="img" src={img_main} alt="img_main" />
        </div>
        
        <p className="first-section-title">xデー後、いよいよ待望のフュージョン機能をリリース！ にゃっちーずNFTを合成して 究極5★のカードをゲットせよ！</p>
        <a href="https://ovobsc.ovo.space/#/airdrop" target="_blank">
          <div className="first-section-img-wrapper">
            <p className="first-section-img-text">エアドロップへ</p>
          </div>
        </a>
        <div className="first-section-desc">
          <div className="base-text">
            <h4 className="h4">強運に恵まれたあなたへ！</h4>
            <p>５★をゲットした場合、なんと、大当たり<span className="text-bold ">7777ドル</span>を</p>
            <p>プレゼント( ﾟДﾟ)！！</p>

            <h4 className="h4 mb-0">沢山のにゃっちーずNFTをお持ちのあなたへ！</h4>
            <p>カードコレクションに挑戦しよう(^_-)</p>
            <p>謎のシークレットカードを手に入れて、</p>
            <p>特<span className="text-bold ">権付きNFT</span>をGET！</p>


            <h4 className="h4">いざ、争奪戦に参加！</h4>
            <p>ガチャボックスに総額2.5万ドルの交換券等が 混じっているよ～ </p>
            <p>交換券を沢山ゲットしてOVOフクロウを困らせよう！</p>
          </div>
          <div className="">
            <img className="img" src={img_7777usdt} alt="img_7777usdt" />
          </div>
          
        </div>

      </section>


      {/* 需要包裹一层div  设置width="100%" 才能缩放 */}
      <section className="second-section-wrapper">
        <div className="second-section">
          <h1 className="second-section-title">ガチャガチャ・ルール</h1>
          <p className="second-section-desc">発行枚数</p>
          <div className="second-section-img-wrapper">
            <div><img src={img_issue1} alt={img_issue1} width="100%"  /></div>
            <div><img src={img_issue2} alt={img_issue2} width="100%" /></div>
            <div><img src={img_issue3} alt={img_issue3} width="100%" /></div>
            <div><img src={img_issue4} alt={img_issue4} width="100%" /></div>
          </div>

          <div className="second-section-img-wrapper">
            <div><img src={img_issue5} alt={img_issue5}  width="100%"  /></div>
            <div><img src={img_issue6} alt={img_issue6}  width="100%" /></div>
            <div><img src={img_issue7} alt={img_issue7}  width="100%" /></div>
            <div><img src={img_issue8} alt={img_issue8}  width="100%" /></div>
          </div>
        </div>
      </section>

      <section className="third-section">
        <h1 className="third-section-title">フュージョン・新ルール</h1>
        <p className="third-section-sub-title">※機能リリースに関しては暫くお待ちください</p>
        <span className="third-section-text-big">フュージョンルールは以下の通りです：</span>
        <div className="third-section-desc">
          <img className="img third-section-img" src={img_form} alt={img_form} />
          <p className="third-section-text">*フュージョンする際に一定量のＳＴ（スタミナ）を消耗する必要があります。</p>
          <p className="third-section-text">詳細につきましては、公式発表を基準にしてください。</p>
        </div>
      </section>

      <section className="fourth-section-wrapper">
        <div className="fourth-section">
          <div className="fourth-section-desc">
            <p><span className="base-text-big">5★</span>NFTはフュージョン機能でしか獲得できません。</p>
            <p>フュージョン機能で<span className="base-text-big">5★</span>を獲得した場合、</p>
            <p><span className="text-red">7777USD</span> 交換券をゲットできます。</p>
            <p>AR効果にゃっちーず権利を入手でき、</p>
            <p>斬新なビジュアルにご期待ください。</p>
          </div>
          <div className="fourth-section-img-wrapper">
            <div><img className="img" src={img_card1} alt={img_card1} /></div>
            <div><img className="img" src={img_card2} alt={img_card2} /></div>
          </div>
        </div>
      </section>

      <section className="fifth-section-wrapper">
        <div className="fifth-section">
          <img src={img_card3} width="96px" height="128px" alt={img_card3} />
          <div className="fifth-section-desc fifth-section-desc-right">
            <p>3★から4★へのフュージョンに失敗した場合、</p>
            <p>ARエボルーションカードを獲得できます。</p>
            <p>ARエボルーションカードは、</p>
            <p>5★カードに適用される特殊カードとして、</p>
            <p>NFTカードをAR化させることが可能です。</p>
            <p>にゃっちーず5★カードにNFT史上初のAR（拡張現実）効果を</p>
            <p>導入し、メタバースへアクセスするためのカギになります！</p>
          </div>
        </div>
        <div className="fifth-section">
        <div className="fifth-section-desc fifth-section-desc-left">
          <p>3★から4★へのフュージョンに成功した場合、</p>
          <p>スペシャル・クリエイトカード（二次創作用）を入手できます。</p>
          <p>コンテスト参加で大賞獲得も可能です。</p>
        </div>
          <img src={img_card4} alt={img_card4} width="96px" height="128px" />
        </div>
        
      </section>

      <section className="sixth-section-wrapper">
        <div className="sixth-section">
           <h1 className="sixth-section-title">コレクション攻略</h1>
           <div className="sixth-section-img-wrapper">
             <img src={img_book} alt={img_book} width="144px" height="93px" />
             <div className="sixth-section-desc">
               <p>指定の３３種類のにゃっちーずカードを集めた場合、</p>
               <p>メインストーリーが含まれたNFT電子ブックを獲得できます。</p>
               <p>また、電子ブックを入手したユーザー様には、特大プレゼントを</p>
               <p>授与しますので、ご期待ください。</p>
             </div>
           </div>
           <div className="sixth-section-desc">
             <p>にゃっちーずカード全７７種類を集めた場合、究極のシークレットカード（5★以上）を</p>
             <p>入手できます。</p>
             <p>シークレットカードは価値は、マイニングパワー等の属性がトップレベルで、</p>
             <p>OVOプラットフォームで最強のカードとも言えるでしょう。</p>
           </div>
        </div>
        
      </section>
      
      <section className="seventh-section">
        <img src={img_33card} width="100%" />
        <div className="seventh-section-img-text">33種類の「にゃっちーず・ストーリーカード」</div>
      </section> 

      <section className="eighth-section-wrapper">
       <div className="eighth-section">
       <h1 className="eighth-section-title">スペシャル・クリエイトカード説明</h1>

       <div className="eighth-section-desc text">
        <p>1、スペシャル・クリエイトカードは特別な3★カードとして、プレイヤー様の二次創作のみに利用
        できます。創作以外、スペシャル・クリエイトカードはマーケットプレイスで取引できますが、
        フュージョンには利用できませんので、予めご了承ください。</p> 
        <p>2、3★から4★へのフュージョンに成功した場合、スペシャル・クリエイトカードを獲得できます。</p>
        <p>スペシャル・クリエイトカードの使い道：</p>
        
        <p>プレイヤー様はスペシャル・クリエイトカードを用いて二次創作した後、定期的に開かれる
        「にゃっちーず創作コンテスト」に応募することができます。</p> 
        
        <p>人気投票を通して選抜を行い、最終的に優勝したプレイヤー様は賞品や賞金をもらえます。</p>
       </div>

       <img className="img" src={img_colour} alt={img_colour} width="358px" height="93px" />
       </div>
      </section>

      <section className="ninth-section">
      <a href="https://ovobsc.ovo.space/#/airdrop" target="_blank">
        <div className="ninth-section-img-wrapper">
            <p className="ninth-section-img-text">エアドロップへ</p>
        </div>
      </a>
      </section>
    </div>
  )
}

export default Home